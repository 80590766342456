:global(.accessibility-mode) .festivalItem {
  border: 4px solid black !important;
  padding: 20px !important;
  background-color: #fff !important;

  .festivalContent {
    padding-left: 0px !important;
    margin-left: -60px !important;
  }

}

.festivalItem {
  margin-bottom: 36px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
  border-radius: 16px;
  padding: 16px 12px;
  padding-right: 0;
  position: relative;
  background-color: var(--text-white);

  a,
  a:hover {
    color: unset;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    width: 90%;
    margin-bottom: 12px;
  }

  .festivalContent {
    padding-left: 24px;
  }

  .festivalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin-top: 40px;

    span {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

@media (max-width: 768px) {
  .festivalItem {
    padding: 0;
    padding-bottom: 8px;
    margin-bottom: 0;

    h3 {
      font-size: 14px;
    }

    .festivalContent {
      padding-left: 12px;

      p {
        margin: 0;
        margin-bottom: 4.5px;
        font-size: 12px;
      }

      .festivalFooter {
        margin-top: 0;

        span {
          font-size: 14px;
        }

        .arrow {
          display: none;
        }
      }
    }
  }
}