.eventWrapper {
  margin-top: 100px;
  h2 {
    text-align: center;
    color: var(--secondary-green);
    margin-bottom: 80px;
  }
  .event_all {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    a {
      display: inline-flex;
      color: var(--text-gray);
      text-decoration: none;
    }
  }
}

@media (max-width: 768px) {
  .eventWrapper {
    margin-top: 24px;
    h2 {
      margin-bottom: 24px;
    }
    .event_date {
      margin-bottom: 11px;
    }
    .event_all {
      margin-top: 12.5px;
    }
  }
}
