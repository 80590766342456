.item {
  background-color: var(--text-white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 24px;
  position: relative;

  .img_wrapper {
    position: relative;
    overflow: hidden;

    .discount_line {
      position: absolute;
      background-color: var(--red);
      color: var(--text-white);
      padding: 12px;
      width: 110%;
      top: 50%;
      font-size: 1em;
      font-weight: 600;
      transform: translateY(-50%) rotate(45deg);
      left: -80px;
      text-align: center;
    }
  }

  a,
  a:hover {
    color: unset;
  }

  .item_img {
    max-width: 100%;
    vertical-align: middle;
  }

  .item_content {
    padding: 16px 24px;

    .title_wrapper {
      border-bottom: 1px solid var(--text-black);
      padding-bottom: 10px;
      margin-bottom: 18px;

      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 21.6px */
        margin-bottom: 8px;
        display: block;
      }

      p {
        color: var(--gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 16.8px */
        margin: 0;
      }

      img {
        border-radius: 100%;
      }
    }

    .content_footer {
      .price_wrapper {
        .price {
          font-size: 1.1rem;
          font-weight: 600;
          line-height: 120%;
          /* 28.8px */
          display: block;

          &.real_price {
            font-size: 1.2em;
            color: var(--red);
          }
        }

        .discount_price {
          text-decoration: line-through;
        }
      }

      .address {
        color: var(--gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 11px 0;
      }

      .rating_list {
        svg {
          fill: var(--gray);
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .item {
    margin-bottom: 48px;
  }
}

@media (min-width: 768px) {
  .item {
    margin-bottom: 24px;
  }
}

@media (min-width: 1492px) {
  .item {
    margin-bottom: 48px;

    .img_wrapper {
      .discount_line {
        left: -140px;
      }
    }
  }
}

@media (max-width: 768px) {
  .item {
    .img_wrapper {
      .discount_line {
        left: -95px;
        font: 0.9em;
      }
    }

    .item_content {
      padding: 7px 8px;

      .title_wrapper {
        .title {
          font-size: 14px;
        }

        p {
          font-size: 12px;
        }

        img {
          width: 21px;
          height: 21px;
        }
      }

      .content_footer {
        .price_wrapper {
          .price {
            font-size: 14px;

            &.real_price {
              font-size: 16px;
            }
          }
        }

        .address {
          .map_icon {
            width: 12px;
            height: 12px;
            margin-left: 4px;
          }

          span {
            font-size: 12px;
          }
        }

        .rating_list {
          // display: none;
        }
      }
    }

    .img_wrapper {
      .discount_line {
        font-size: 12px;
        left: 0;
        transform: translateY(0%) rotate(0deg);
        width: 100%;
        top: 0;

      }
    }
  }
}